import { FC } from 'react';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css/bundle';

import { BlogPostCard } from '@/components/shared/BlogPostCard';
import { Typography } from '@/components/shared/Typography';

import { CasestudiesItems, InsightsItems } from '@/lib/api/types/basic';

SwiperCore.use([Navigation]);

interface Props {
  preline?: string | null;
  title?: string | null;
  items?: InsightsItems['items'] | CasestudiesItems['items'];
}

export const TemplateBottomSlider: FC<Props> = ({ preline, title, items }) => {
  if (!items) {
    return null;
  }

  return (
    <section className="max-w-[160rem] w-full mx-auto pl-[1.6rem] md:pl-[3.6rem] flex gap-[3.2rem] flex-col lg:flex-row mb-[4.4rem] lg:mb-[8.8rem]">
      <div className="flex flex-col w-full gap-[0.8rem] mb-[1.6rem] lg:mb-[3.2rem]">
        {preline && <Typography variant="preline">{preline}</Typography>}
        {title && (
          <Typography tag="h3" variant="h2" isBold bottomSpace="normal">
            {title}
          </Typography>
        )}

        <div className="mt-[3.2rem] w-full">
          <Swiper
            style={{
              width: '100%',
              height: '100%'
            }}
            speed={500}
            grabCursor
            slidesPerView="auto"
            spaceBetween={32}
          >
            {items.map((item) => (
              <SwiperSlide key={item?.id} style={{ width: '100%', maxWidth: '510px' }}>
                <BlogPostCard
                  image={item?.content?.mainImage}
                  slug={item?.full_slug}
                  date={item?.first_published_at}
                  title={item?.content?.cardTitle}
                  description={item?.content?.cardDescription}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </section>
  );
};
