import { FC } from 'react';

import { TemplateBottomSlider } from '@/components/shared/TemplateBottomSlider';
import { BlokDynamicZone, BlokProvider } from '@/components/storyblok/BlokProvider';
import { CaseStudiesTemplate } from '@/components/storyblok/CaseStudiesTemplate/CaseStudiesTemplate';

import { CaseStudiesProps } from '@/lib/types';
import { getDataSourceEntry } from '@/lib/utils/getDataSourceEntry/getDataSourceEntry';
import { isCaseStudiesType } from '@/lib/utils/isCaseStudiesType/isCaseStudiesType';

export const CaseStudies: FC<CaseStudiesProps> = ({
  data,
  preview,
  bottomSliderData,
  datasourceEntries
}) => {
  if (!isCaseStudiesType(data)) {
    return null;
  }

  const bottomSliderTitle = getDataSourceEntry(
    'bottom_case_studies_slider_title',
    datasourceEntries
  );

  return (
    <div className="mt-[8rem] lg:mt-[11rem]">
      <section>
        <CaseStudiesTemplate data={data} preview={preview}>
          {(blok) => <BlokProvider blok={blok as BlokDynamicZone} />}
        </CaseStudiesTemplate>
      </section>
      <TemplateBottomSlider title={bottomSliderTitle} items={bottomSliderData?.items} />
    </div>
  );
};
