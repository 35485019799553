import { useRouter } from 'next/router';
import { FC, useMemo } from 'react';

import { ArrowIcon } from '@/components/shared/Icons/ArrowIcon';
import { PaginationItem } from '@/components/shared/PaginationItem';

import { useLang } from '@/hooks/useLang';
import { usePagination } from '@/hooks/usePagination';

import { INSIGHTS_BLOG_POSTS_PER_PAGE } from '@/lib/consts';
import { insightRootSlugs } from '@/lib/utils';

interface Props {
  total: number;
  currentPage: number;
}

export const InsightPostsPagination: FC<Props> = ({ total, currentPage }) => {
  const { locale } = useLang();
  const { push } = useRouter();

  const { prevPage, nextPage, range } = usePagination({
    total,
    max: INSIGHTS_BLOG_POSTS_PER_PAGE,
    currentPage
  });

  const currentInsightItem = useMemo(
    () => insightRootSlugs.find((item) => item.locale === locale),
    [locale]
  );

  if (!currentInsightItem) {
    return null;
  }

  const { slug } = currentInsightItem;

  return (
    <div className="w-full max-w-[160rem] mx-auto px-[1.6rem] md:px-[3.6rem] mb-[4.4rem] lg:mb-[8.8rem] flex justify-end">
      <div className="flex items-center ">
        {prevPage && (
          <button
            className="transition-colors group flex items-center justify-between rotate-180 mr-[1.6rem] text-primary hover:text-hoverPrimary focus:text-black"
            onClick={() => push(prevPage === 1 ? `/${slug}` : `/${slug}/page/${prevPage}`)}
          >
            <ArrowIcon />
          </button>
        )}
        <ul className="flex gap-[1rem]">
          {range.map((link, index) => (
            <li key={index} className="self-end">
              <PaginationItem item={link} slug={slug} />
            </li>
          ))}
        </ul>
        {nextPage && (
          <button
            className="transition-colors group flex items-center justify-between ml-[1.6rem] text-primary hover:text-hoverPrimary focus:text-black"
            onClick={() => push(`/${slug}/page/${nextPage}`)}
          >
            <ArrowIcon />
          </button>
        )}
      </div>
    </div>
  );
};
