import React, { FC } from 'react';

import { TemplateBottomSlider } from '@/components/shared/TemplateBottomSlider';
import { BlokDynamicZone, BlokProvider } from '@/components/storyblok/BlokProvider';
import { PageTemplate } from '@/components/storyblok/PageTemplate';

import { ContainerProps } from '@/lib/types';
import { isPageType } from '@/lib/utils';
import { getDataSourceEntry } from '@/lib/utils/getDataSourceEntry/getDataSourceEntry';

export const DefaultTemplate: FC<ContainerProps> = ({
  data,
  preview,
  bottomSliderData,
  datasourceEntries
}) => {
  if (!isPageType(data)) {
    return null;
  }

  const bottomSliderTitle = getDataSourceEntry(
    'bottom_case_studies_slider_title',
    datasourceEntries
  );

  return (
    <>
      <PageTemplate data={data} preview={preview}>
        {(blok) => <BlokProvider blok={blok as BlokDynamicZone} />}
      </PageTemplate>
      <TemplateBottomSlider title={bottomSliderTitle} items={bottomSliderData?.items} />
    </>
  );
};
