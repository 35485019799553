import { FC } from 'react';

import { Page, PageProps } from '@/components/storyblok/Page';

import { InsightsItem } from '@/lib/api/types/basic';
import { getInsights } from '@/lib/api/utils';
import { usePreviewMode, useStoryblok } from '@/lib/storyblok-sdk';
import { isInsightPostType } from '@/lib/utils';

interface Props extends Pick<PageProps, 'children'> {
  preview: boolean;
  data: InsightsItem;
  relations?: string;
}

export const InsightPostTemplate: FC<Props> = ({ data, preview, children, relations = '' }) => {
  usePreviewMode(preview);

  const handlePageItemLoad = async (slug: string): Promise<InsightsItem | null> => {
    const data = await getInsights({ slug });

    return data;
  };

  const story = useStoryblok(data, preview, handlePageItemLoad, relations);

  return isInsightPostType(story) ? <Page data={story}>{children}</Page> : null;
};
