import { FC, useEffect, useState } from 'react';
import { NewsArticle, WithContext } from 'schema-dts';

import { ButtonLink } from '@/components/shared/ButtonLink';
import { Image } from '@/components/shared/Image';
import { SBLink } from '@/components/shared/SBLink';
import { StructuredData } from '@/components/shared/StructuredData';

import { Asset, Maybe } from '@/lib/api/types/basic';
import { StoryblokLink } from '@/lib/types/utils';
import { getDate } from '@/lib/utils';
import { getOrganizationSchema } from '@/lib/utils/getOrganizationSchema';

import { Typography } from '../Typography';

export interface BlogPostCardProps {
  image?: Maybe<Asset> | undefined;
  title?: string | null;
  description?: string | null;
  slug?: string | null;
  date?: string | null;
}

const link: StoryblokLink = {
  cached_url: '',
  fieldtype: '',
  id: '',
  linktype: 'story',
  url: '',
  email: ''
};

export const BlogPostCard: FC<BlogPostCardProps> = ({ image, title, slug, date, description }) => {
  const formatedDate = date ? getDate(date) : null;
  // TODO fix this later
  // https://ittutorialpoint.com/solved-react-18-hydration-failed-because-the-initial-ui-does-not-match-what-was-rendered-on-the-server/
  const [showChild, setShowChild] = useState(false);
  useEffect(() => {
    setShowChild(true);
  }, []);

  if (!showChild) {
    return null;
  }

  const schema: WithContext<NewsArticle> = {
    '@context': 'https://schema.org',
    '@type': 'NewsArticle',
    author: getOrganizationSchema(),
    publisher: getOrganizationSchema(),
    ...(typeof title === 'string' && { headline: title }),
    ...(typeof description === 'string' && { description: description }),
    ...(image && image.filename && { image: [image.filename] }),
    ...(typeof date === 'string' && { datePublished: date }),
    ...(typeof slug === 'string' && { mainEntityOfPage: slug }),
    ...(typeof slug === 'string' && { url: slug })
  };

  return (
    <>
      <StructuredData data={schema} />

      <SBLink link={{ ...link, cached_url: slug ?? '' }}>
        <a className="max-w-[51rem] w-full flex flex-col">
          <div className="relative mb-[2.6rem]">
            {image && image.filename && (
              <Image
                src={image.filename}
                alt={image.alt || ''}
                layout="responsive"
                width={375}
                height={300}
                objectFit="contain"
                objectPosition="center"
              />
            )}
          </div>
          <div className="flex flex-col flex-1">
            {formatedDate && <Typography className="mb-[0.6rem]">{formatedDate}</Typography>}
            {title && (
              <Typography isBold className="mb-[0.8rem]">
                {title}
              </Typography>
            )}
            {description && (
              <Typography tag="p" variant="bodySmall" className="mb-[1.8rem]">
                {description}
              </Typography>
            )}
            <span className="mt-auto">
              {slug && <ButtonLink variant="arrow" as="span" className="mt-[1.8rem]" />}
            </span>
          </div>
        </a>
      </SBLink>
    </>
  );
};
