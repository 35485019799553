import NextHead from 'next/head';
import { useRouter } from 'next/router';
import { FC } from 'react';

import { TemplateBottomSlider } from '@/components/shared/TemplateBottomSlider';
import { BlokDynamicZone, BlokProvider } from '@/components/storyblok/BlokProvider';
import { InsightRootTemplate } from '@/components/storyblok/InsightRootTemplate';

import { useLang } from '@/hooks/useLang';

import { InsightRootProps } from '@/lib/types';
import { insightRootSlugs, isInsightsCategoryType } from '@/lib/utils';
import { getDataSourceEntry } from '@/lib/utils/getDataSourceEntry/getDataSourceEntry';

import { BlogPostsGrid } from './components/BlogPostsGrid/BlogPostsGrid';
import { Categories, isInsightscategoryItem } from './components/Categories/Categories';
import { InsightPostsPagination } from './components/InsightPostsPagination';
import { withPaginationPage, metaRobots, getCurrentPage } from './utils';

export const InsightRoot: FC<InsightRootProps> = ({
  data,
  preview,
  categories,
  posts,
  bottomSliderData,
  datasourceEntries
}) => {
  const { asPath } = useRouter();
  const { locale } = useLang();

  const currentInsightItem = insightRootSlugs.find((item) => item.locale === locale);
  const withPagination = withPaginationPage(['page', 'insights'], currentInsightItem?.slug);

  if (!isInsightsCategoryType(data)) {
    return null;
  }

  const currentPage = getCurrentPage(asPath);

  const categoryItems = categories?.items;
  const totalPostItems = posts?.total || 1;

  const bottomSliderTitle = getDataSourceEntry(
    'bottom_case_studies_slider_title',
    datasourceEntries
  );

  return (
    <>
      {withPagination && (
        <NextHead>
          <meta
            name="robots"
            key="robots"
            content={currentPage === 1 ? metaRobots.INDEX : metaRobots.NOINDEX}
          />
        </NextHead>
      )}
      <div>
        <InsightRootTemplate data={data} preview={preview}>
          {(blok) => <BlokProvider blok={blok as BlokDynamicZone} />}
        </InsightRootTemplate>
        {isInsightscategoryItem(categoryItems) && <Categories items={categoryItems} />}
        {posts && <BlogPostsGrid posts={posts} />}
        {withPagination && (
          <InsightPostsPagination total={totalPostItems} currentPage={currentPage} />
        )}
        <TemplateBottomSlider title={bottomSliderTitle} items={bottomSliderData?.items} />
      </div>
    </>
  );
};
