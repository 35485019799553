import { FC } from 'react';

import { Chips } from '@/components/shared/Chips';
import { SBLink } from '@/components/shared/SBLink';

import { Maybe, Story } from '@/lib/api/types/basic';
import { StoryblokLink } from '@/lib/types/utils';

interface Props {
  categories?: Maybe<Array<Maybe<Story>>>;
}

const isStoryType = (data: any): data is Story[] => {
  return data !== undefined && data !== null && data.length > 0;
};

export const InsightCategories: FC<Props> = ({ categories }) => {
  if (!isStoryType(categories)) {
    return null;
  }

  return (
    <div className="w-full max-w-[160rem] mx-auto px-[1.6rem] md:px-[3.6rem] mb-[4.4rem] lg:mb-[9.3rem]">
      <div className="flex gap-[0.8rem] flex-wrap">
        {categories?.map(({ id, fullSlug, name }) => (
          <SBLink
            key={id}
            link={{ cached_url: fullSlug ?? '/', linktype: 'story' } as StoryblokLink}
          >
            <Chips label={name!} as="a" />
          </SBLink>
        ))}
      </div>
    </div>
  );
};
