import { useRouter } from 'next/router';
import { FC } from 'react';

import { ButtonLink } from '@/components/shared/ButtonLink';

interface Props {
  item: number | 'dots';
  slug: string;
}

export const PaginationItem: FC<Props> = ({ item, slug }) => {
  const { push } = useRouter();

  const handleClick = () => {
    const href = item === 1 ? slug : `${slug}/page/${item}`;

    push(href);
  };

  if (typeof item === 'number') {
    return (
      <ButtonLink as="button" onClick={handleClick} withUnderline={false} className="px-[0.6rem]">
        {item}
      </ButtonLink>
    );
  }

  if (item === 'dots') {
    return <span className="text-primary">...</span>;
  }

  return null;
};
