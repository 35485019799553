/**
 * "If the slugs array contains the word 'page' or the slugs array is equal to the url, return true."
 *
 * The function is used to determine if the current page is a paginated page
 * @param {string[] | string} [slugs] - The array of slugs that are in the URL.
 * @param {string} [url] - The current URL
 */
export const withPaginationPage = (slugs?: string[] | string, url?: string) =>
  ((slugs && slugs.includes('page')) || slugs?.length === 1) && slugs.includes(url || '');

/**
 * It takes an array of strings and returns the current page number
 * @param {string[] | string} [slugs] - The slugs from the URL.
 * @returns The current page number
 */
export const getCurrentPage = (slug?: string) => {
  const slugs = slug?.split('/').filter(Boolean);

  if (slugs && slugs?.length === 1) {
    return 1;
  }

  if (slugs && Array.isArray(slugs) && slugs.includes('page')) {
    const page = slugs[2];

    return +page;
  }

  return 1;
};

export const metaRobots = {
  INDEX: 'index, follow',
  NOINDEX: 'noindex, nofollow',
  NOINDEX_FOLLOW: 'noindex, follow',
  INDEX_NOFOLLOW: 'index, nofollow'
} as const;
