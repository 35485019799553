import type { GetStaticPaths, GetStaticProps, NextPage } from 'next';
import dynamic from 'next/dynamic';
import { FC } from 'react';

import { CaseStudies } from '@/components/layout/CaseStudies';
import { DefaultTemplate } from '@/components/layout/DefaultTemplate';
import { InsightPost } from '@/components/layout/InsightPost';
import { InsightRoot } from '@/components/layout/InsightRoot';
import { isSearchedItems } from '@/components/layout/SearchProvider';
import { Seo } from '@/components/shared/Seo';
import { BlokDynamicZone, BlokProvider } from '@/components/storyblok/BlokProvider';

import { defaultLang, Locale } from '@/hooks/useLang';

import { getDataSourceEntries } from '@/lib/api/utils';
import { getLinks } from '@/lib/api/utils/getLinks';
import { getSearchedItems } from '@/lib/api/utils/getSearchedItems';
import { RoutingProps, SlugParams } from '@/lib/types';
import {
  getCaseStudiesTemplateData,
  getDefaultTemplateData,
  getInsightPostTemplateData,
  getInsightRootTemplateData,
  getLocalePrefix,
  getPaths
} from '@/lib/utils';
import { getSeoData } from '@/lib/utils/getSeoData';
import { getSlugFromParams } from '@/lib/utils/getSlugFromParams';
import { getTemplateType, TemplatesType } from '@/lib/utils/getTemplateType';

const Placeholder = dynamic<any>(
  () => import('@/components/storyblok/Placeholder').then((mod) => mod.Placeholder),
  { ssr: false }
);

const Templates = {
  page: DefaultTemplate,
  insightsCategory: InsightRoot,
  insights: InsightPost,
  caseStudies: CaseStudies
};

// Why? netlify rate limit - max 500 request per 1 min (429 error - too many requests)
// Previously was 20 pages but this is not enough.
const MAXIMUM_NUMBER_OF_PAGES_ON_BUILD_TIME =
  process.env.NEXT_MAX_NUMBER_OF_PAGES_ON_BUILD_TIME ?? 15;

const Routing: NextPage<RoutingProps> = ({
  data,
  preview,
  templateType,
  bottomSliderData,
  datasourceEntries,
  // insight post
  topInsightsPosts,
  prevAndNextPost,
  // root page
  categories,
  posts,
  // all
  searchedItems
}) => {
  if (templateType && typeof Templates[templateType] !== undefined) {
    const Template = Templates[templateType] as FC<any>; // TODO check this
    const seo = getSeoData(data);

    return (
      <>
        <Seo seo={seo} />
        <Template
          data={data}
          preview={preview}
          datasourceEntries={datasourceEntries}
          topInsightsPosts={topInsightsPosts}
          prevAndNextPost={prevAndNextPost}
          categories={categories}
          posts={posts}
          bottomSliderData={bottomSliderData}
          searchedItems={searchedItems}
        />
        {data?.content?.scripts?.map((script: BlokDynamicZone) => (
          <BlokProvider key={script._uid} blok={script} />
        ))}
      </>
    );
  }

  return <Placeholder componentName={templateType} />;
};

export const getStaticPaths: GetStaticPaths = async () => {
  const locale = process.env.I18N_CONFIG?.toLowerCase().replace('_', '-') as Locale;
  const localePrefix = `${getLocalePrefix(locale)}/`;

  const links = await getLinks({ startsWith: localePrefix });

  // Generate paths for all pages
  const pathsOfAllPages = getPaths(links);

  // Create valid array for getStaticPaths
  const paths = [...pathsOfAllPages];

  return {
    paths: paths.slice(0, Number(MAXIMUM_NUMBER_OF_PAGES_ON_BUILD_TIME)),
    fallback: 'blocking'
  };
};

export const getStaticProps: GetStaticProps<RoutingProps, SlugParams> = async ({
  preview = false,
  locale: lang = defaultLang,
  params
}) => {
  const locale = lang.toLowerCase() as Locale;
  const localePrefix = getLocalePrefix(locale);

  const slug = getSlugFromParams({ params });

  let templateType: TemplatesType = null;

  if (slug.includes('insights/page/')) {
    templateType = 'insightsCategory';
  } else if (slug.includes('faq/entries/')) {
    templateType = null;
  } else {
    templateType = await getTemplateType({ params, locale: localePrefix });
  }

  if (!templateType) {
    return {
      notFound: true
    };
  }

  const contentNodes = await getSearchedItems({
    startsWith: `${locale}/`,
    searchTerm: ''
  });
  const searchedItems = isSearchedItems(contentNodes?.items) ? contentNodes?.items : null;

  const datasourceEntries = await getDataSourceEntries({ locale });

  // ['insights/[post]']
  if (templateType === 'insights') {
    const res = await getInsightPostTemplateData({
      locale: localePrefix,
      params,
      templateType,
      preview
    });

    return {
      props: {
        ...res.props,
        searchedItems,
        datasourceEntries
      }
    };
  }

  // ['insights', 'category/ux', ...]
  if (templateType === 'insightsCategory') {
    const res = await getInsightRootTemplateData({
      locale: localePrefix,
      params,
      templateType,
      preview
    });

    return {
      props: {
        ...res.props,
        searchedItems,
        datasourceEntries
      }
    };
  }

  if (templateType === 'caseStudies') {
    const res = await getCaseStudiesTemplateData({
      locale: localePrefix,
      params,
      templateType,
      preview
    });

    return {
      props: {
        ...res.props,
        searchedItems,
        datasourceEntries
      }
    };
  }

  // Fetch default data for all pages there
  const res = await getDefaultTemplateData({ locale: localePrefix, params, templateType, preview });

  return {
    props: {
      ...res.props,
      searchedItems,
      datasourceEntries
    }
  };
};

export default Routing;
