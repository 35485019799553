import Link from 'next/link';
import { useRouter } from 'next/router';
import { FC } from 'react';

import { ButtonLink } from '@/components/shared/ButtonLink';
import { SBLink } from '@/components/shared/SBLink';

interface Props {
  backLabel?: string | null;
  nextLink?: string;
  prevLink?: string;
}

const mockLink = {
  cached_url: '',
  fieldtype: '',
  id: '',
  linktype: '',
  url: '',
  email: ''
};

export const TopPagination: FC<Props> = ({ backLabel, prevLink, nextLink }) => {
  const { query } = useRouter();
  const backLink = (query?.slug && Array.isArray(query.slug) && query.slug[0]) || '';

  return (
    <div className="w-full max-w-[160rem] mx-auto flex items-center justify-between mt-[6rem] lg:mt-[12rem] mb-[4.4rem] lg:mb-[9.5rem] px-[1.6rem] md:px-[3.6rem]">
      {backLink && (
        <Link href={`/${backLink}`} passHref>
          <ButtonLink>{backLabel}</ButtonLink>
        </Link>
      )}

      <div className="flex gap-[5.6rem]">
        <SBLink link={{ ...mockLink, cached_url: prevLink ?? '', linktype: 'story' }}>
          <ButtonLink variant="arrow" className="rotate-180" disabled={!prevLink} />
        </SBLink>

        <SBLink link={{ ...mockLink, cached_url: nextLink ?? '', linktype: 'story' }}>
          <ButtonLink variant="arrow" disabled={!nextLink} />
        </SBLink>
      </div>
    </div>
  );
};
