import { useMemo } from 'react';

import { range } from '@/lib/utils';

interface UsePaginationInput {
  total?: number;
  max?: number;
  currentPage?: number;
}

export const DOTS = 'dots';

/**
 * It takes a total number of items, a max number of items per page, and a current page number, and
 * returns a range of page numbers to display, the current page number, the last page number, the next
 * page number, and the previous page number
 * @param {UsePaginationInput}  - total - the total number of items
 * @returns An object with the following properties:
 * - range: An array of numbers and dots
 * - currentPage: The current page number
 * - lastPage: The last page number
 * - nextPage: The next page number
 * - prevPage: The previous page number
 */
export const usePagination = ({ total = 0, max = 12, currentPage = 1 }: UsePaginationInput) => {
  const lastPage = Math.ceil(total / max);

  const paginationRange = useMemo((): (number | 'dots')[] => {
    const totalPageNumbers = 7;

    if (totalPageNumbers >= lastPage) {
      return range(1, lastPage);
    }

    const leftSiblingIndex = Math.max(currentPage - 1, 1);
    const rightSiblingIndex = Math.min(currentPage + 1, lastPage - 1);

    const shouldShowLeftDots = leftSiblingIndex > 3;
    const shouldShowRightDots = rightSiblingIndex < lastPage - 2;

    if (!shouldShowLeftDots && shouldShowRightDots) {
      const leftItemCount = 5;

      return [...range(1, leftItemCount), DOTS, ...range(lastPage, lastPage)];
    }

    if (shouldShowLeftDots && !shouldShowRightDots) {
      const rightItemCount = 4;

      return [...range(1, 1), DOTS, ...range(lastPage - rightItemCount, lastPage)];
    }

    return [
      ...range(1, 1),
      DOTS,
      ...range(leftSiblingIndex, rightSiblingIndex),
      DOTS,
      ...range(lastPage - 2, lastPage)
    ];
  }, [lastPage, currentPage]);

  return {
    range: paginationRange,
    currentPage,
    lastPage,
    nextPage: currentPage + 1 > lastPage ? null : currentPage + 1,
    prevPage: currentPage - 1 === 0 ? null : currentPage - 1
  };
};
