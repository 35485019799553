import { FC, ReactNode } from 'react';

import { BlokCommonProps } from '@/components/storyblok/BlokProvider';

import {
  CasestudiesItem,
  InsightscategoryItem,
  InsightsItem,
  PageItem
} from '@/lib/api/types/basic';

export type PageProps = {
  data: PageItem | InsightsItem | InsightscategoryItem | CasestudiesItem;
  children: (blok: unknown) => ReactNode;
};

export const Page: FC<PageProps> = ({ data, children }) => {
  if (!data.content?.body) {
    return null;
  }

  return data.content.body.map((blok: BlokCommonProps) => (
    <div key={blok._uid}>{children(blok)}</div>
  ));
};
