import { FC } from 'react';

import { ShareWebsite } from '@/components/nextjs/ShareWebsite';
import { TemplateBottomSlider } from '@/components/shared/TemplateBottomSlider';
import { BlokDynamicZone, BlokProvider } from '@/components/storyblok/BlokProvider';
import { InsightPostTemplate } from '@/components/storyblok/InsightPostTemplate';

import { InsightPostProps } from '@/lib/types';
import { isInsightPostType } from '@/lib/utils';

import { useDatasourceEntries } from '../DatasourceEntriesProvider';
import { InsightCategories } from './components/InsightCategories';
import { TopPagination } from './components/PostPagination';

export const InsightPost: FC<InsightPostProps> = ({
  data,
  preview,
  topInsightsPosts,
  prevAndNextPost
}) => {
  const { t } = useDatasourceEntries();

  if (!isInsightPostType(data)) {
    return null;
  }

  const sliderPreline = t('slider_preline');
  const sliderTitle = t('slider_title');
  const backLabel = t('back_label');
  const shareLabel = t('share_label');

  const categories = data.content?.categories;

  const { prev, next } = prevAndNextPost || {};

  return (
    <div className="mt-[8rem] lg:mt-[11rem]">
      <TopPagination backLabel={backLabel} prevLink={prev ?? ''} nextLink={next ?? ''} />
      <section>
        <InsightPostTemplate data={data} preview={preview}>
          {(blok) => <BlokProvider blok={blok as BlokDynamicZone} />}
        </InsightPostTemplate>
      </section>
      <InsightCategories categories={categories} />
      <ShareWebsite title={shareLabel} />
      <TemplateBottomSlider
        preline={sliderPreline}
        title={sliderTitle}
        items={topInsightsPosts?.items}
      />
    </div>
  );
};
