import { FC } from 'react';

import { Page, PageProps } from '@/components/storyblok/Page';

import { CasestudiesItem } from '@/lib/api/types/basic';
import { getCaseStudies } from '@/lib/api/utils';
import { usePreviewMode, useStoryblok } from '@/lib/storyblok-sdk';
import { isCaseStudiesType } from '@/lib/utils/isCaseStudiesType/isCaseStudiesType';
interface Props extends Pick<PageProps, 'children'> {
  preview: boolean;
  data: CasestudiesItem;
  relations?: string;
}

export const CaseStudiesTemplate: FC<Props> = ({ children, preview, data, relations }) => {
  usePreviewMode(preview);

  const handlePageItemLoad = async (slug: string): Promise<CasestudiesItem | null> => {
    const data = await getCaseStudies({ slug: `${slug}` });

    return data;
  };

  const story = useStoryblok(data, preview, handlePageItemLoad, relations);

  return isCaseStudiesType(story) ? <Page data={story}>{children}</Page> : null;
};
