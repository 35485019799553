import { useRouter } from 'next/router';
import { FC } from 'react';

import { Chips } from '@/components/shared/Chips';
import { SBLink } from '@/components/shared/SBLink';

import { useLang } from '@/hooks/useLang';

import { InsightscategoryItem } from '@/lib/api/types/basic';

interface Props {
  items: InsightscategoryItem[];
}

const mockLink = {
  cached_url: '',
  fieldtype: '',
  id: '',
  linktype: '',
  url: '',
  email: ''
};

export const isInsightscategoryItem = (data: any): data is InsightscategoryItem[] => {
  return data !== undefined && data !== null && Array.isArray(data) && data.length > 0;
};

export const Categories: FC<Props> = ({ items }) => {
  const { asPath } = useRouter();
  const { locale } = useLang();

  const rootItem = items.find(({ is_startpage }) => is_startpage);
  const filteredItems = items.filter(
    (item) => item.id !== rootItem?.id && item.full_slug?.includes('insights')
  );

  const localeWithPath = `${locale}${asPath}`;

  return (
    <div className="w-full max-w-[160rem] mx-auto px-[1.6rem] md:px-[3.6rem] mb-[4.4rem] lg:mb-[8.8rem]">
      <ul className="flex gap-[0.8rem] items-center flex-wrap">
        <li>
          <SBLink link={{ ...mockLink, cached_url: rootItem?.full_slug ?? '', linktype: 'story' }}>
            <Chips
              label={rootItem?.content?.name || ''}
              as="a"
              isSelected={asPath === '/' + rootItem?.slug || asPath.includes('page')}
            />
          </SBLink>
        </li>
        {filteredItems.map((item) => (
          <li key={item?.id}>
            <SBLink link={{ ...mockLink, cached_url: item?.full_slug ?? '', linktype: 'story' }}>
              <Chips
                label={item?.content?.name || ''}
                as="a"
                isSelected={localeWithPath === item?.full_slug}
              />
            </SBLink>
          </li>
        ))}
      </ul>
    </div>
  );
};
