import { SeoComponent } from '@/components/shared/Seo/types';

import {
  CasestudiesItem,
  InsightscategoryItem,
  InsightsItem,
  PageItem
} from '@/lib/api/types/basic';

/**
 * It takes a data object and returns the seo object from the data object
 * @param {PageItem | InsightsItem | InsightscategoryItem | CasestudiesItem | null} data - PageItem | InsightsItem |
 * InsightscategoryItem | null
 * @returns The seo object from the data object.
 */
export const getSeoData = (
  data?: PageItem | InsightsItem | InsightscategoryItem | CasestudiesItem | null
) => {
  const seo: SeoComponent | undefined = (data?.content?.seo && data?.content?.seo[0]) || undefined;

  return seo;
};
