import { FC } from 'react';

import { Page, PageProps } from '@/components/storyblok/Page';

import { InsightscategoryItem } from '@/lib/api/types/basic';
import { getInsightsCategory } from '@/lib/api/utils/getInsightsCategory/getInsightsCategory';
import { usePreviewMode, useStoryblok } from '@/lib/storyblok-sdk';
import { isInsightsCategoryType } from '@/lib/utils';

interface Props extends Pick<PageProps, 'children'> {
  preview: boolean;
  data: InsightscategoryItem;
  relations?: string;
}

export const InsightRootTemplate: FC<Props> = ({ data, preview, children, relations = '' }) => {
  usePreviewMode(preview);

  const handlePageItemLoad = async (slug: string): Promise<InsightscategoryItem | null> => {
    const data = await getInsightsCategory({ slug, relations });

    return data;
  };

  const story = useStoryblok(data, preview, handlePageItemLoad, relations);

  return isInsightsCategoryType(story) ? <Page data={story}>{children}</Page> : null;
};
