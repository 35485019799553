import { FC } from 'react';

import { BlogPostCard } from '@/components/shared/BlogPostCard';

import { InsightsItem, InsightsItems } from '@/lib/api/types/basic';

interface BlogPostsGridProps {
  posts: InsightsItems;
}

const isInsightsItem = (data: any): data is InsightsItem[] => data !== undefined && data !== null;

export const BlogPostsGrid: FC<BlogPostsGridProps> = ({ posts }) => {
  const items = posts.items?.filter((item) => item);

  if (!isInsightsItem(items)) {
    return null;
  }

  return (
    <div className="w-full max-w-[160rem] mx-auto px-[1.6rem] md:px-[3.6rem] mb-[4.4rem] lg:mb-[8.8rem]">
      <ul className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-[3.4rem] lg:gap-x-[3.4rem] lg:gap-y-[9rem]">
        {items.map((item) => (
          <li key={item.id} className="flex justify-center">
            <BlogPostCard
              image={item?.content?.mainImage}
              slug={item?.full_slug}
              date={item?.first_published_at}
              title={item?.content?.cardTitle}
              description={item?.content?.cardDescription}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
